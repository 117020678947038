// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { firebaseContext } from './FirebaseProvider';
import { Scalars } from '../__generated__/pge-types';
import { onValue, ref } from 'firebase/database';

interface ContactDataIntercept {
  isEnabled?: boolean;
  startDate?: Scalars['LocalDate'];
  endDate?: Scalars['LocalDate'];
}

interface FeatureFlags {
  isPlannedDownTime: boolean;
  startStopEnabled: boolean;
  moveEnabled: boolean;
  loading: boolean;
  isTPAEnabled: boolean;
  isOutageByAddressEnabled: boolean;
  isClosedAccountsEnabled?: boolean;
  isPowerPortfolioEnabled?: boolean;
  isClosedAccountsEnabled?: boolean;
  contactDataIntercept?: ContactDataIntercept;
  displayMyAccountInfoBanner: boolean;
  isPGEPlusEVEnabled: boolean;
  isPGEPlusInstallerSignInEnabled: boolean;
  isNewAccountDashboardEnabled: boolean;
  isClientProgramPromoModalEnabled: boolean;
  isSecureSiteModalEnabled: boolean;
  isPGEPlusRebateOnlyEnabled: boolean;
}

const initialState: FeatureFlags = {
  // runtime: firebase flags
  isPlannedDownTime: false,
  startStopEnabled: false,
  moveEnabled: false,
  loading: true,
  isTPAEnabled: false,
  isOutageByAddressEnabled: false,
  isClosedAccountsEnabled: false,
  isPowerPortfolioEnabled: false,
  isClosedAccountsEnabled: false,
  displayMyAccountInfoBanner: false,
  isPGEPlusEVEnabled: false,
  isPGEPlusInstallerSignInEnabled: false,
  isNewAccountDashboardEnabled: false,
  isClientProgramPromoModalEnabled: false,
  isSecureSiteModalEnabled: false,
  isPGEPlusRebateOnlyEnabled: false,
};

const featureFlagsContext = React.createContext<FeatureFlags>(initialState);
const { Provider } = featureFlagsContext;

const FeatureFlagsProvider = (props: any) => {
  const { database } = useContext(firebaseContext);
  const [value, setValue] = useState(initialState);

  useEffect(() => {
    /* Important: Using firebase real-time database listner.
     * onValue() is called every time data is changed on featureFlags database.
     */
    const featureFlagRef = ref(database, '/featureFlags');
    onValue(featureFlagRef, snapshot => {
      if (snapshot.exists()) {
        setValue({
          ...value,
          isPlannedDownTime: !!snapshot.val()?.isPlannedDownTime,
          startStopEnabled: !!snapshot.val()?.startStopEnabled,
          moveEnabled: !!snapshot.val()?.moveEnabled,
          isTPAEnabled: !!snapshot.val()?.isTPAEnabled,
          isOutageByAddressEnabled: !!snapshot.val()?.isOutageByAddressEnabled,
          isPowerPortfolioEnabled: !!snapshot.val()?.isPowerPortfolioEnabled,
          isClosedAccountsEnabled: !!snapshot.val()?.isClosedAccountsEnabled,
          contactDataIntercept: snapshot.val()?.contactDataIntercept,
          displayMyAccountInfoBanner: !!snapshot.val()
            ?.displayMyAccountInfoBanner,
          isPGEPlusEVEnabled: !!snapshot.val()?.isPGEPlusEVEnabled,
          isPGEPlusInstallerSignInEnabled: !!snapshot.val()
            ?.isPGEPlusInstallerSignInEnabled,
          isNewAccountDashboardEnabled: !!snapshot.val()
            ?.isNewAccountDashboardEnabled,
          isClientProgramPromoModalEnabled: !!snapshot.val()
            ?.isClientProgramPromoModalEnabled,
          isSecureSiteModalEnabled: !!snapshot.val()?.isSecureSiteModalEnabled,
          isPGEPlusRebateOnlyEnabled: !!snapshot.val()
            ?.isPGEPlusRebateOnlyEnabled,
          loading: false,
        });
      }
    });
  }, [database]);

  return <Provider value={value}>{props.children}</Provider>;
};

export { FeatureFlagsProvider, featureFlagsContext, ContactDataIntercept };
