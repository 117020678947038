import gql from 'not-graphql-tag';

const dropdownInfoFragment = gql`
  fragment DropdownInfo on AccountDetail {
    isDefault
    accountNumber
    accountType
    encryptedAccountNumber
    encryptedPersonId
    description
    mainCustomerName
    isActive
    premiseInfo {
      addressLine1
      addressLine2
      city
      state
      postal
    }
    serviceConnectivity {
      isEligibleForReconnect
      isDisconnected
      isReconnectInProgress
    }
  }
`;

export const getSelectedAccount = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      ...DropdownInfo
    }
  }
  ${dropdownInfoFragment}
`;

export const getAccountsList = gql`
  query getAccountDropdownInfo($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      totalCount
      accounts {
        ...DropdownInfo
      }
    }
  }
  ${dropdownInfoFragment}
`;
