import { useTranslation } from './useTranslation';
import useFormState, { convertValidationRules } from './useFormState';
import {
  SendOtlResponse,
  ValidateOtlResponse,
} from '../__generated__/pge-types';
import { validateEmail } from '../util/form-validation';
import gql from 'not-graphql-tag';
import { BaseValidationContext } from './useFormState.types';
import { useApolloClient } from '@apollo/react-hooks';
import useWrapWithLoader from './useWrapWithLoading';
import { useContext, useEffect, useMemo, useState } from 'react';
import { NotificationsContext } from '../providers/NotificationsProvider';
import usePgeQuery from './usePgeQuery';
import authService from '../lib/authService';
import { navigate } from '@reach/router';
import ROUTES from '../routes';
import { getFromStorage } from '../util/storage-utils';

export type Model = {
  email: string;
};

export const OneTimeLoginTokenErrors = Object.freeze({
  AccountLocked: 'AccountLocked',
});

function getToken(querySearchParam: string): string {
  const x = querySearchParam.split('request=', 2);
  return x.length === 2 ? x[1] : '';
}

const sendOTLQuery = gql`
  query sendOTL($params: SendOTLRequest!) {
    sendOTL(params: $params) {
      isSuccess
    }
  }
`;

const validateOTLTokenQuery = gql`
  query validateOTLToken($params: ValidateOTLRequest!) {
    validateOTLToken(params: $params) {
      customToken
      isExpired
    }
  }
`;

export default function useQuickLoginForm(props: {
  newEmail?: string | undefined;
  previousEmail?: string | undefined;
  closeModal?: () => void;
}) {
  const { newEmail, previousEmail, closeModal } = props;
  const notificationContext = useContext(NotificationsContext);
  const [successMessage, setSuccessMessage] = useState(false);
  const { wrapWithLoader } = useWrapWithLoader();
  const apolloClient = useApolloClient();
  const { t, richT } = useTranslation();

  const token = useMemo(() => {
    return typeof window !== 'undefined'
      ? getToken(window.location.search)
      : '';
  }, []);
  const emailFromStorage: string | undefined = useMemo(
    () => getFromStorage('userEmail'),
    [],
  );
  let defaultEmail = emailFromStorage;

  if (newEmail) {
    defaultEmail = newEmail;
  }

  if (previousEmail) {
    defaultEmail = previousEmail;
  }
  const {
    data: validateData,
    loading: oneTimeTokenLoading,
    error,
  } = usePgeQuery<{
    validateOTLToken: ValidateOtlResponse;
  }>(validateOTLTokenQuery, {
    variables: {
      params: {
        token: token,
      },
    },
    skip: !token,
    errorPolicy: 'all', // stop it going to default error page
  });

  const validateAndSignInWithToken = async (customToken: string) => {
    await authService.signInWithCustomToken(customToken);
  };
  useEffect(() => {
    if (
      token &&
      validateData?.validateOTLToken?.customToken &&
      !validateData?.validateOTLToken?.isExpired
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      validateAndSignInWithToken(validateData?.validateOTLToken?.customToken);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.ACCOUNT, { replace: true });
    } else {
      return;
    }
  }, [validateData?.validateOTLToken, token]);
  const defaultFormValues: Model = {
    email: defaultEmail || '',
  };
  const form = useFormState(
    {
      ...defaultFormValues,
    },
    { validate: createOneTimeLoginValidateFunction() },
  );
  const HandleError = (errorMessage: any) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    notificationContext.setState({
      isOpen: true,
      message: errorMessage,
      severity: 'error',
    });
    typeof window !== 'undefined' && window.scrollTo(0, 0);
  };

  const handleBack = async (): Promise<void> => {
    setSuccessMessage(false);
  };

  const handleSubmit = wrapWithLoader(async () => {
    setSuccessMessage(false);
    const { data: oneTimeLoginData, errors } = await apolloClient.query<{
      sendOTL: SendOtlResponse;
    }>({
      query: sendOTLQuery,
      variables: {
        params: {
          email: form.values.email ? form.values.email.toLowerCase() : defaultEmail?.toLowerCase(),
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    if (errors?.length) {
      if (errors[0].message?.trim() === OneTimeLoginTokenErrors.AccountLocked) {
        HandleError(
          richT('PASSWORD_ATTEMPTS_EXCEEDED_DAILY_LIMIT', {
            CUSTOMER_SERVICE_NUMBER: `tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`,
          }),
        );
      } else {
        HandleError(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'));
      }
    } else {
      form.reset(defaultFormValues);
      setSuccessMessage(oneTimeLoginData?.sendOTL.isSuccess!);
      notificationContext.setState({
        isOpen: true,
        message: t('ONE_TIME_LOGIN_SUBMIT_MESSAGE'),
        severity: 'success',
      });
    }
    closeModal && void closeModal();
  });

  return {
    quickform: form,
    quickLoginhandleSubmit: handleSubmit,
    successMessage,
    token,
    oneTimeTokenLoading,
    handleBack,
    tokenStatus:
      !oneTimeTokenLoading && validateData?.validateOTLToken?.isExpired,
  };
}
export const createOneTimeLoginValidateFunction = () =>
  convertValidationRules<Model>((context: BaseValidationContext<Model>) => {
    return {
      email: validateEmail,
    };
  });
