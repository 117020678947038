import gql from 'not-graphql-tag';

export const getAccountTypeQuery = gql`
  query getAccountDetailsAccountType($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      accountType
    }
  }
`;
