export default {
  lightCyan: '#20415B',
  sparkBlue: '#27788C',
  shoreGreen: '#43936F',
  riverBlue: '#EAF5f8',
  noirBlur: '#3D5265',
  forestGreen: '#A1BD55',
  sunsetOrange: '#F3AD3C',
  roseRed: '#D63F2F',
  orange: '#F06D06',
  cloudGrey: '#F7F7F7',
  mountainBrown: '#977C63',
  darkGray1: '#3d3d3d',
  darkGray2: '#686c71',
  darkGray3: '#84898F',
  lightGray1: '#d7d7d7',
  lightGray2: '#F7F7F7',
  siteBackground: '#f7f8f7',
  navBarBackground: '#FFFFFF',
  navBarHoverBackground: '#EAF5F8',
  navBarSelectLine: '#00929B',
  mobileNavQuickLinks: '#F9F29D',
  buttonHoverPrimary: '#356D8C',
  buttonHoverSecondary: '#006FB70d', // F2
  buttonActiveSecondary: '#006FB726', // F2
  focusedVisible: '#005084',
  megaMenuBackLight: '#0075BB',
  megaMenuBackDark: '#00487D',
  megaMenuBackground: '#EAF5F8',
  white: '#fff',
  black: '#000',
  darkGreen: '#315045',
  darkRed: '#772B21',
  darkOrange: '#79561E',
  darkBlue: '#01385A',
  lightBlue: '#E1F8FA',
  lightBlue1: '#EAF5f8',
  boxShadow: '#000029',
  charcoal: '#707070',
  boxShadowLight: '#8C8C8C4D',
  oliveDrab: '#176796',
  aliceBlue: '#F4FBFF',
  prussianBlue: '#003554',
  navyBlue: '#006DBA',
  raven: '#696C71',
  whiteSmoke: '#F4F4F4',
  blackRussian: '#00000D',
  errorRed: '#D82912',
  gray97: '#F7F7F7',
  gray93: '#EDEDED',
  darkBlue2: '#274159',
  darkerRed: '#C73B25',
  lightSkyBlue: '#d6e8f3',
  rhinoBlack: '#28425a',
  boxShadowColor: '#00000029',
  modalBackgroundColor: '#E6F5FF',
  disableColor: '#E0E0E0',
  quickSilverGrey: '#A6A6A6',
  modalBlue: '#E6F5F8',
  brightBlue: '#176EBF',
  aluminiumColor: '#868B90',
  midGrey: '#686C71',
  noirBlurLight: '#1C304A',
  fordGray: '#979797',
  gray100: '#edf2f7',
  gray300: '#e2e8f0',
  gray600: '#718096',
  waikawaGray: '#5C7B99',
  gray800: '#2d3748',
  indigo500: '#667eea',
  gray82: '#D1D1D1',
  lightGray3: '#efefef',
  lightGray4: '#757575',
  lightGray5: '#757575',
  lightGray6: '#f8f8f8',
  scrollbarBackground: '#CFDCE5',
  backgroundLightBlue: '#F4F9FC',
  tooltipBoxShadow: '#00002930',
  boxShadowBlack: '#00000033',
  buttonColor: '#26788C',
  inputFieldBackground: '#F0F0F0',
  mobileMenuLvlTwoBackground: '#D8D5D5',
  linenWhite: '#FEFDF2',
  warning: '#E57710',
  info: '#1462A6',
  applicationBanner: '#F9F29D',
  tableBodyData: '#20415B',
  formFieldBorder: '#565656',
  popoverTooltip: '#20415B',
  orangeBrown: '#C96100',
  lightGray7: '#F1F1F1',
  iconBlue: '#0371B4',
  vibrantOrange: '#F06D08',
  vibrantBlue: '#0271B4',
  buttonHoverBackground: '#1A5462',
  greenCheckBg: '#43936E',
  chromeWhite: '#E5F1DA',
  poloBlue: '#7AA5CC',
  chetwodeBlue: '#8884d8',
  blueWhale: '#032943',
  blackSqueeze: '#F7FBFC',
  lightYellow: '#FBFAC4',
};
